var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    {
      attrs: { mini: "", type: "text", disabled: _vm.disabled },
      on: { click: _vm.toggleEnable }
    },
    [
      _c("el-badge", {
        staticClass: "item enable-dot",
        attrs: { "is-dot": "", type: _vm.value ? "success" : "danger" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }