import request from '@/utils/request';
export var toggleEnable = function toggleEnable(url, data) {
  return request({
    url: url,
    method: 'patch',
    data: data
  });
};
export var uploadFile = function uploadFile(data) {
  var url = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/common/upload';
  var onUploadProgressCallBack = arguments.length > 2 ? arguments[2] : undefined;
  return request({
    url: url,
    method: 'post',
    data: data,
    onUploadProgress: onUploadProgressCallBack
  });
};
export var getAppVersions = function getAppVersions() {
  return request({
    url: '/common/app_version',
    method: 'get'
  });
};