import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultAttributeData = {
  id: 0,
  enabled: true,
  name: ''
};
export var getAttributes = function getAttributes(params) {
  return request({
    url: '/attributes',
    method: 'get',
    params: params
  });
};
export var getAttributesTree = function getAttributesTree(data) {
  return request({
    url: '/attributes/tree',
    method: 'post',
    data: data
  });
};
export var getAttributeById = function getAttributeById(id) {
  return request({
    url: "/attributes/".concat(id),
    method: 'get'
  });
};
export var updateAttribute = function updateAttribute(id, data) {
  return request({
    url: "/attributes/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteAttribute = function deleteAttribute(id) {
  return request({
    url: "/attributes/".concat(id),
    method: 'delete'
  });
};
export var createAttribute = function createAttribute(data) {
  return request({
    url: '/attributes/',
    method: 'post',
    data: data
  });
};